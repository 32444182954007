import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { useNavigate, useLocation } from "react-router-dom";

function ResetPassword() {
    const navigate = useNavigate();
    const location = useLocation();
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [error, setError] = useState(null);
    const [passwordShow, setPasswordShow] = useState(false);
    const [confirmPasswordShow, setConfirmPasswordShow] = useState(false);
    const [tokenVerified, setTokenVerified] = useState(false);

    const token = decodeURI(location.search.split('=')[1].split('&')[0]);
    
    useEffect(() => {
        if (token) {
            axios.post(`${process.env.REACT_APP_BASE_URL}/patient/v1/check-token`, { token })
                .then(res => {
                    if (res.data.status === 'success') {
                        setTokenVerified(true);
                    }
                })
                .catch(err => {
                    setTokenVerified(false);
                })
        }
    }, [token])

    const send = () => {
        if (password === confirmPassword) {
            axios.post(`${process.env.REACT_APP_BASE_URL}/patient/v1/reset-password`, {
                token: token,
                password: password,
            }, { headers: {
                "Content-Type": "application/json",
                "Accept-Language": "ar",
                'Authorization': `Bearer ${token}`
            }}).then(res => {
                navigate('/resetPassword-success');
            }).catch(err => {
                setError(err.response.data.message);
            });
        } else {
            setError('كلمة المرور غير متطابقة ');   
        }
    }

    if (!token) return <div className='error-container'><span className='error-msg'>No Token</span></div>;
    //console.log(labsList);

    return (
        <div>
            <div className='resetPassword_container'>
                <div className='resetPassword'>
                    <div className='resetPassword_image'>
                        <img src='/images/authimg.png' alt='authimg' />
                    </div>
                    <div className='resetPassword_form'>
                        <img src='/images/logo.png' alt='logo' className='reset-logo' />
                        <img src='/images/reset.png' alt='lock' className='reset-img' />
                        {   tokenVerified 
                                ? <h2>تعيين كلمة المرور</h2> 
                                : <h2 style={{ color: "#C00", marginTop: "25px", textAlign: "center" }}>لقد تم تعيين كلمة المرور بالفعل، وليس من الممكن تعيينها مرة أخرى</h2>}
                        {
                            tokenVerified && (
                                <>
                                    <div className='input_container'>
                                        <label htmlFor='password'><span>* </span>كلمة المرور</label>
                                        <div className='input_form'>
                                            <input type='password' id='password' placeholder='**********' value={password} onChange={(e) => setPassword(e.target.value)} />  
                                            {!passwordShow && <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokWidth="1.5" stroke="currentColor"
                                                            onClick={() => {
                                                                document.getElementById('password').type = 'test';
                                                                setPasswordShow(true);
                                                            }}>
                                                <path strokeLinecap="round" strokeLinejoin="round" d="M3.98 8.223A10.477 10.477 0 001.934 12C3.226 16.338 7.244 19.5 12 19.5c.993 0 1.953-.138 2.863-.395M6.228 6.228A10.45 10.45 0 0112 4.5c4.756 0 8.773 3.162 10.065 7.498a10.523 10.523 0 01-4.293 5.774M6.228 6.228L3 3m3.228 3.228l3.65 3.65m7.894 7.894L21 21m-3.228-3.228l-3.65-3.65m0 0a3 3 0 10-4.243-4.243m4.242 4.242L9.88 9.88" />
                                            </svg>}
                                            {passwordShow && <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor"
                                                    onClick={() => {
                                                        document.getElementById('password').type = 'password';
                                                        setPasswordShow(false);
                                                    }} >
                                                <path strokeLinecap="round" strokeLinejoin="round" d="M2.036 12.322a1.012 1.012 0 010-.639C3.423 7.51 7.36 4.5 12 4.5c4.638 0 8.573 3.007 9.963 7.178.07.207.07.431 0 .639C20.577 16.49 16.64 19.5 12 19.5c-4.638 0-8.573-3.007-9.963-7.178z" />
                                                <path strokeLinecap="round" strokeLinejoin="round" d="M15 12a3 3 0 11-6 0 3 3 0 016 0z" />
                                            </svg>}

                                        </div>
                                    </div>
                                    <div className='input_container'>
                                        <label htmlFor='confirmPassword'><span>* </span>تأكيد كلمة المرور</label>
                                        <div className='input_form'>
                                            <input type='password' id='confirmPassword' placeholder='**********' value={confirmPassword} onChange={(e) => setConfirmPassword(e.target.value)} />
                                            {!confirmPasswordShow && <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokWidth="1.5" stroke="currentColor"
                                                            onClick={() => {
                                                                document.getElementById('confirmPassword').type = 'text';
                                                                setConfirmPasswordShow(true);
                                                            }}>
                                                <path strokeLinecap="round" strokeLinejoin="round" d="M3.98 8.223A10.477 10.477 0 001.934 12C3.226 16.338 7.244 19.5 12 19.5c.993 0 1.953-.138 2.863-.395M6.228 6.228A10.45 10.45 0 0112 4.5c4.756 0 8.773 3.162 10.065 7.498a10.523 10.523 0 01-4.293 5.774M6.228 6.228L3 3m3.228 3.228l3.65 3.65m7.894 7.894L21 21m-3.228-3.228l-3.65-3.65m0 0a3 3 0 10-4.243-4.243m4.242 4.242L9.88 9.88" />
                                            </svg>}
                                            {confirmPasswordShow && <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor"
                                                    onClick={() => {
                                                        document.getElementById('confirmPassword').type = 'password';
                                                        setConfirmPasswordShow(false);
                                                    }} >
                                                <path strokeLinecap="round" strokeLinejoin="round" d="M2.036 12.322a1.012 1.012 0 010-.639C3.423 7.51 7.36 4.5 12 4.5c4.638 0 8.573 3.007 9.963 7.178.07.207.07.431 0 .639C20.577 16.49 16.64 19.5 12 19.5c-4.638 0-8.573-3.007-9.963-7.178z" />
                                                <path strokeLinecap="round" strokeLinejoin="round" d="M15 12a3 3 0 11-6 0 3 3 0 016 0z" />
                                            </svg>}

                                        </div>
                                    </div>
                                    <button className='btn' onClick={send}>تعيين كلمة المرور</button>
                                    {error && <span className='error_msg'>{error}</span>}
                                </>
                            )
                        }
                        
                    </div>
                </div>
            </div>
        </div>
  )
}

export default ResetPassword
