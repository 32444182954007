import React from 'react';

function ResetPasswordSuccess() {
   
    return (
        <div>
            <div className='resetPassword_container'>
                <div className='resetPassword'>
                    <div className='resetPassword_image'>
                        <img src='/images/authimg.png' alt='authimg' />
                    </div>
                    <div className='resetPassword_form'>
                        <img src='/images/logo.png' alt='logo' className='reset-logo' />
                        <img src='/images/confirm_password.png' alt='lock' className='reset-img' />
                        <h2>تم تعيين كلمة المرور بنجاح</h2>
                        <p>تسجيل الدخول</p>
                        <div className='apps_icons_container'>
                                <a href='https://apps.apple.com/' target="_blank" className='apps_icons' rel="noreferrer">
                                    <img src='/images/ios.png' alt='appstore' />
                                </a>
                                <a href='https://play.google.com/store/apps' target="_blank" className='apps_icons' rel="noreferrer">
                                    <img src='/images/android.png' alt='appstore' />
                                </a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
  )
}

export default ResetPasswordSuccess
